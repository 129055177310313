export const CompetencyLevelsEnums = {
  1: {
    key: 1,
    value: '★',
  },
  2: {
    key: 2,
    value: '★★',
  },
  3: {
    key: 3,
    value: '★★★',
  },
  4: {
    key: 4,
    value: '★★★★',
  },
  5: {
    key: 5,
    value: '★★★★★',
  },
};

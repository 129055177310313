export const ManageFolderPermissions = {
  CreateFolder: {
    key: '9f4d9d8cd2f2439199e808fc1e8ad54c',
  },
  UpdateFolder: {
    key: '0be1970ffc9f450c80b5389bdcd25920',
  },
  DeleteFolder: {
    key: 'a998014c9dfa4516aa8e07330761ab12',
  },
  ViewFolder: {
    key: '1154f4a0d08041bc89138208f41953e7',
  },
};

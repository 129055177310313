export const TableActionsThemesEnum = {
  Buttons: {
    key: 1,
    value: 'buttons',
  },
  Popover: {
    key: 2,
    value: 'popover',
  },
};

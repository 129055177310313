export const AssessmentTestMembersTypeEnum = {
  JobCandidate: {
    key: 5,
    value: 'candidates',
  },
  JobStage: {
    key: 6,
    value: 'stages',
  },
};

export const PipelineQuickFilterLocationsEnum = {
  JobPipeline: {
    key: "job_pipeline",
  },
  InitialApproval: {
    key: "initial_approval",
  },
  SearchDatabase: {
    key: "search_database",
  },
};

export const OnboardingGroupByActionsEnum = {
  Members: {
    key: 1,
    label: 'members',
    group_by: 'members',
  },
  Fields: {
    key: 2,
    label: 'fields',
    group_by: 'fields',
  },
};

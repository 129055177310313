export const EvaBrandSectionsEnum = {
  Menu: {
    key: 0,
    name: 'menu',
    icon: 'fas fa-bars',
    isDraggable: false,
    isDeletable: false,
    isMultiple: false,
    isDisabled: false, // disabled until handle its dialog
    isEditable: true,
    isHideable: false,
  },
  Header: {
    key: 1,
    name: 'header',
    icon: 'fas fa-home',
    isDraggable: false,
    isDeletable: false,
    isMultiple: false,
    isDisabled: false, // disabled until handle its dialog
    isEditable: true,
    isHideable: false,
  },
  Footer: {
    key: 99,
    name: 'footer',
    icon: 'fas fa-window-maximize fa-flip-vertical',
    isDraggable: false,
    isDeletable: false,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  MediaAndTextSection: {
    key: 2,
    name: 'media-text',
    icon: 'fas fa-photo-video',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  OpeningsSection: {
    key: 3,
    name: 'openings',
    icon: 'far fa-newspaper',
    isDraggable: true,
    isDeletable: false,
    isMultiple: false,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  GridSection: {
    key: 4,
    name: 'grid',
    icon: 'fas fa-table',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  GallerySection: {
    key: 5,
    name: 'gallery',
    icon: 'far fa-images',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  TeamMembersSection: {
    key: 6,
    name: 'team-members',
    icon: 'fas fa-users',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  SliderSection: {
    key: 7,
    name: 'slider',
    icon: 'fas fa-sliders-h',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  LocationSection: {
    key: 8,
    name: 'location',
    icon: 'fas fa-map-marked-alt',
    isDraggable: true,
    isDeletable: true,
    isMultiple: false,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  QuotesSection: {
    key: 9,
    name: 'quotes',
    icon: 'fas fa-quote-left',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  PartnersSection: {
    key: 10,
    name: 'partners',
    icon: 'far fa-handshake',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  SuccessStoriesSection: {
    key: 11,
    name: 'success-stories',
    icon: 'far fa-thumbs-up',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
  JobCategoriesSection: {
    key: 12,
    name: 'job-categories',
    icon: 'fas fa-briefcase',
    isDraggable: true,
    isDeletable: true,
    isMultiple: true,
    isDisabled: false,
    isEditable: true,
    isHideable: true,
  },
};

export const OnboardingTasksStatusesEnum = {
  NotCompleted: {
    key: 0,
    value: 'not-completed',
  },
  Completed: {
    key: 1,
    value: 'completed',
  },
};

export const ProfileManagementFeaturesEnum = {
  Job: {
    key: 1,
  },
  SearchDB: {
    key: 2,
  },
  DrApproval: {
    key: 3,
  },
  Invite: {
    key: 4,
  },
};

export const ScorecardFormAppereanceEnum = {
  sideDrawer: {
    key: 1,
    label: 'drawer',
  },
  fullPage: {
    key: 2,
    label: 'full-page',
  },
};

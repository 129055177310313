export const QuestionnaireFromFeatureEnum = {
  EvaREC: {
    key: 1,
    value: 'eva-rec',
  },
  EvaSSESS: {
    key: 2,
    value: 'eva-ssess',
  },
};

export const CandidateAssignmentStatusesEnum = {
  Assigned: {
    key: 'yes',
    value: 'yes',
  },
  NotAssigned: {
    key: 'no',
    value: 'no',
  },
};

export const EvaRecTaskManagementPermissions = {
  CreateTask: {
    key: '566f6b25412b4e4d82ef2440dce8bea0',
  },
  UpdateTask: {
    key: '52fa9a2a599742b3a73d5633c50e0ef3',
  },
  UpdateTaskStatus: {
    key: 'b31bf60a821543cdb58ab71e9e90f328',
  },
  DeleteTask: {
    key: '454c69f2e99840c09b0780dbf61b03f1',
  },
};

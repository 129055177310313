export const ThirdPartiesLoginTypesEnum = {
  Domain: {
    key: 'domain',
    value: 'domain',
  },
  AccountNumber: {
    key: 'account-number',
    value: 'account-number',
  },
};

export const WorkflowsGroupsEnum = {
  One: {
    key: 1,
  },
  Two: {
    key: 2,
  },
  Three: {
    key: 3,
  },
};

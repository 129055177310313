export const AnalyticsDashboardPermissionsTypesEnum = {
  View: {
    key: 'view',
    value: 'view',
  },
  Edit: {
    key: 'edit',
    value: 'edit',
  },
};

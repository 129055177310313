export const OfferAssignTypesEnum = {
  JobCandidate: {
    key: 1,
    value: 'candidates',
  },
  JobStage: {
    key: 2,
    value: 'stages',
  },
};

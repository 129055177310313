export const BinzagerSapPaymentTypeEnum = {
  BankTransfer: {
    key: 'P',
    value: 'bank-transfer',
  },
  Cheques: {
    key: 'O',
    value: 'cheques',
  },
};

export const FormsForTypesEnum = {
  Candidate: {
    key: 1,
    value: 'candidate',
  },
  SystemUser: {
    key: 2,
    value: 'system-user',
  },
};

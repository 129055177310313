export const PartnersLookupsEnum = {
  Departments: {
    key: 'departments',
  },
  OutsourcingCompanies: {
    key: 'outsourcing_companies',
  },
  JobTypes: {
    key: 'job_types',
  },
  Nationality: {
    key: 'nationality',
  },
  Locations: {
    key: 'locations',
  },
  DeelCountries: {
    key: 'deel_countries',
  },
  NationalIDType: {
    key: 'hcm_national_id_types',
  },
  BusinessUnitID: {
    key: 'hcm_business_units',
  },
  LegalEntityID: {
    key: 'hcm_legal_entities',
  },
  OracleHCMCountries: {
    key: 'hcm_countries',
  },
  OracleHCMActionCode: {
    key: 'actions',
  },
  OracleHCMAddressTypes: {
    key: 'address_types',
  },
  DocumentTypes: {
    key: 'document_types',
  },
  OracleHCMDepartments: {
    key: 'hcm_departments',
  },
  OracleHCMLocations: {
    key: 'hcm_locations',
  },
  OracleHCMJobs: {
    key: 'hcm_jobs',
  },
  OracleHCMNationalities: {
    key: 'hcm_nationality',
  },
  OracleHCMOrganizationTreeNodes: {
    key: 'hcm_organization_tree_node',
  },
  OracleHCMOrganizationClassifications: {
    key: 'hcm_organization_classification',
  },
  OracleHCMOrganizationDff: {
    key: 'hcm_organization_dff',
  },
  OracleHCMOrganizations: {
    key: 'hcm_organizations',
  },
  OracleHCMEmployees: {
    key: 'hcm_employees',
  },
  OracleHCMUserAccounts: {
    key: 'hcm_user_accounts',
  },
  OracleHCMPositions: {
    key: 'hcm_positions',
  },
};

export const FormsSubmissionsLevelsTypesEnum = {
  FormLevel: {
    key: 1,
    value: 'form-level',
  },
  FieldsLevel: {
    key: 2,
    value: 'fields-level',
  },
};

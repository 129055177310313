export const TeamsRulesOperationsEnum = {
  where_in: {
    key: 'where_in',
    value: 'where_in',
  },
  where_not_in: {
    key: 'where_not_in',
    value: 'where_not_in',
  },
};

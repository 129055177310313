import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { getDataFromObject, StringToColor } from '../../../../helpers';
import i18next from 'i18next';

const TableAvatarsView = ({
  row,
  rowIndex,
  columnKey,
  isWithText,
  maxAvatars,
  idRef,
  options,
}) => (
  <div className="table-avatars-wrapper table-view-wrapper d-inline-flex">
    {(isWithText
      && ((Array.isArray(getDataFromObject(row, columnKey, true))
        && getDataFromObject(row, columnKey, true).map((item, index) => (
          <div
            className="d-inline-flex-v-center"
            key={`${idRef}${index + 1}-${rowIndex + 1}`}
          >
            <Avatar style={{ backgroundColor: StringToColor(item) }}>
              {item && item.split(' ').map((word) => word[0])}
            </Avatar>
            {isWithText ? (
              options.is_link && row[options.url_key] ? (
                <a
                  href={row[options.url_key]}
                  target={options.is_new_tab && '_blank'}
                >
                  <span className="px-2">{item}</span>
                </a>
              ) : (
                <span className="px-2">{item}</span>
              )
            ) : (
              ''
            )}
          </div>
        )))
        || (typeof getDataFromObject(row, columnKey, true) === 'string' && (
          <div className="d-inline-flex-v-center">
            <Avatar
              style={{
                backgroundColor: StringToColor(getDataFromObject(row, columnKey)),
              }}
            >
              {getDataFromObject(row, columnKey, true)
                && getDataFromObject(row, columnKey, true)
                  .split(' ')
                  .map((word) => word[0])}
            </Avatar>
            {isWithText ? (
              options.is_link && row[options.url_key] ? (
                <a
                  href={row[options.url_key]}
                  target={options.is_new_tab && '_blank'}
                >
                  <Typography className="px-2">
                    {getDataFromObject(row, columnKey, true)}
                  </Typography>
                </a>
              ) : (
                <Typography className="px-2">
                  {getDataFromObject(row, columnKey, true)}
                </Typography>
              )
            ) : (
              ''
            )}
          </div>
        )))) || (
      <AvatarGroup max={maxAvatars}>
        {(Array.isArray(getDataFromObject(row, columnKey, true))
          && getDataFromObject(row, columnKey, true).map((item, index) => (
            <Avatar
              style={{ backgroundColor: StringToColor(item) }}
              key={`${idRef}${index + 1}-${rowIndex + 1}`}
              title={item && (item?.[i18next.language] || item.en || item || '')}
            >
              {(item && (item?.[i18next.language] || item.en || item || ''))
                .split(' ')
                .map((word) => word[0])}
            </Avatar>
          )))
          || (typeof getDataFromObject(row, columnKey, true) === 'string' && (
            <Avatar
              style={{
                backgroundColor: StringToColor(getDataFromObject(row, columnKey)),
              }}
              title={getDataFromObject(row, columnKey, true)}
            >
              {getDataFromObject(row, columnKey, true)
                && getDataFromObject(row, columnKey, true)
                  .split(' ')
                  .map((word) => word[0])}
            </Avatar>
          ))}
      </AvatarGroup>
    )}
  </div>
);

TableAvatarsView.propTypes = {
  columnKey: PropTypes.string.isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  rowIndex: PropTypes.number.isRequired,
  maxAvatars: PropTypes.number,
  isWithText: PropTypes.bool,
  idRef: PropTypes.string,
};

TableAvatarsView.defaultProps = {
  maxAvatars: 5,
  isWithText: false,
  idRef: 'TableAvatarsViewRef',
};

export default TableAvatarsView;

export const CandidateLogsActionsTypes = {
  MoveToStage: {
    key: 1,
  },
  SendQuestionnaire: {
    key: 2,
  },
  SendVideoAssessment: {
    key: 3,
  },
  SendAssessmentTest: {
    key: 4,
  },
  UpdateProfile: {
    key: 5,
  },
  StageAction: {
    key: 6,
  },
};

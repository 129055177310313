export const OracleHcmWorkerTypes = {
  Employee: {
    key: "E",
    value: 'employee',
  },
  ContingentWorker: {
    key: "C",
    value: 'contingent-worker',
  },
  PendingWorker : {
    key: "P",
    value: 'pending-worker ',
  },
  NonWorker: {
    key: "N",
    value: 'non-worker',
  },
};

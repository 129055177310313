export const PipelinePreMoveErrorTypesEnum = {
  StageLimit: {
    key: 'stage_limit',
    value: 'stage-limit',
  },
  Precondition: {
    key: 'precondition',
    value: 'precondition',
  },
};

export const ScorecardAppereanceEnum = {
  steps: {
    key: 1,
    label: 'steps',
  },
  scroll: {
    key: 2,
    label: 'scroll',
  },
};

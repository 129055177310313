export const BranchesSlugSendTypesEnum = {
  Single: {
    key: 'single',
    value: 'single',
  },
  Multiple: {
    key: 'multiple',
    value: 'multiple',
  },
};

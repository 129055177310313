export const EvaluationTypesEnum = {
  OneToFive: {
    key: 1,
    value: '1-to-5',
  },
  Percentage: {
    key: 2,
    value: 'percentage',
  },
};

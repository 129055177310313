export const ScorecardStylesEnum = {
  square: {
    key: 1,
    label: 'square-with-numbers',
  },
  star: {
    key: 2,
    label: 'stars',
  },
};

export const CompetencySourcesEnums = {
  1: {
    key: 1,
    value: 'SELF',
  },
  2: {
    key: 2,
    value: 'EDUCATION',
  },
  3: {
    key: 3,
    value: 'TALENT_DEPARTMENT',
  },
  4: {
    key: 4,
    value: 'PERFORMANCE_APPRAISAL',
  },
};

export const ManageSpacePermissions = {
  CreateSpace: {
    key: '78cd55f1cb0c4be8b7777857c9bef0e5',
  },
  UpdateSpace: {
    key: '80ac777b151b487bb9b8645873112fce',
  },
  DeleteSpace: {
    key: '8dc6a37f60b4466e8a48e47f430a4c9b',
  },
  ViewSpace: {
    key: 'fc39aa2e17924fd9b4abefb6958b8250',
  },
};

export const SettingsCandidatesRegistrationTypesEnum = {
  VerificationLink: {
    key: 1,
    value: 'verification-link',
  },
  EasySignupAndApply: {
    key: 2,
    value: 'easy-signup-and-apply',
  },
};

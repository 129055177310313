export const PagesFilterInitValue = {
  tags: [], // { key: '', value: '' }
  custom_tags: [],
  job_type: [],
  degree_type: [],
  major: [],
  career_level: [],
  country: [],
  industry: [],
  nationality: [],
  language: [],
  gender: '',
  // reference_number: '',
  // applicant_number: '',
  checkboxFilters: {},
  is_include: true,
  query: [],
  // score: 0,
  // years_of_experience: 0,
  assigned_user_uuid: [],
  candidate_property: [],
  assigned_employee_uuid: [],
  source_type: null,
  source: null,
  assessment_test_status: null,
  rms_filters: [],
  category_uuid: [],
  interested_position_title: [],
  academic_certificate: [],
  date_filter_type: 'default',
  candidate_type: '',
  va_assessment_status: '',
  questionnaire_status: '',
  va_assessment_uuid: [],
  questionnaire_uuid: [],
  has_assignee: '',
};

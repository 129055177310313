export const AssigneeTypesEnum = {
  User: {
    key: 1,
    value: 'user',
  },
  Employee: {
    key: 2,
    value: 'employee',
  },
};

export const FormRequestMoreInfoEnum = {
  1: {
    key: 1,
    label: 'Package offer',
  },
  2: {
    key: 2,
    label: 'Benefits',
  },
  3: {
    key: 3,
    label: 'Role and responsibilities',
  },
  4: {
    key: 4,
    label: 'Reporting Manager',
  },
  5: {
    key: 5,
    label: 'Others',
  },
};

export const ManageDirectoryPermissions = {
  ManageDirectory: {
    key: '5b348c17c0c04f5583a742b3e12d5d17',
  },
  ManageSendInvitation: {
    key: '987bc6fa69764aad8586407e842b34d5',
  },
  ManageReorder: {
    key: 'e07c5840c6054b789ad736046439bc3d',
  },
  ManageLinkFlow: {
    key: '3e3209c2684749868ef2034a0662cb30',
  },
};

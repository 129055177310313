export const TablesViewEnum = {
  Avatar: {
    key: 1,
  },
  Switch: {
    key: 2,
  },
  TextAndAvatar: {
    key: 3,
  },
  Label: {
    key: 7,
  },
};

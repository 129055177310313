export const CandidateTypesEnum = {
  Employee: {
    key: 1,
    value: 'employee',
  },
  Candidate: {
    key: 2,
    value: 'candidate',
  },
};

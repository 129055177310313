export const PipelineTaskMainConditionOperatorsEnum = {
  IF: {
    key: 'if',
    label: 'if-statement',
    value: false,
  },
  ELSE: {
    key: 'else',
    label: 'else-if-statement',
    value: true,
  },
};

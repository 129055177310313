export const ScoreCalculationTypeEnum = {
  default: {
    key: 1,
    label: 'default',
  },
  weight: {
    key: 2,
    label: 'weight',
  },
};

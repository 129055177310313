export const FormsAssistRoleTypesEnum = {
  Editor: {
    key: 1,
    value: 'editor',
  },
  Viewer: {
    key: 2,
    value: 'viewer',
  },
};

export const AssignTypesOfApprovals = {
  User: {
    key: 'user',
    value: 'users',
  },
  Team: {
    key: 'team',
    value: 'team',
  },
  Position: {
    key: 'position',
    value: 'position',
  },
  Employee: {
    key: 'employee',
    value: 'employee',
  },
};

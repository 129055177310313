export const SystemActionsEnum = {
  edit: {
    key: 'edit',
    icon: 'fas fa-edit',
    color: 'c-gray-primary',
    bgColor: 'bg-gray',
    value: 'Shared:edit',
  },
  delete: {
    key: 'delete',
    icon: 'far fa-trash-alt',
    color: 'c-warning',
    bgColor: 'bg-warning',
    value: 'Shared:delete',
  },
  add: {
    key: 'add',
    icon: 'fas fa-plus',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:delete',
  },
  view: {
    key: 'view',
    icon: 'far fa-eye',
    iconAlt: 'far fa-eye-slash',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:view',
  },
  addUser: {
    key: 'addUser',
    icon: 'fas fa-user-plus',
    iconAlt: 'fas fa-user-plus',
    value: 'Shared:view',
  },
  userInfo: {
    key: 'userInfo',
    icon: 'fas fa-user-lock',
    iconAlt: 'fas fa-user-unlock',
    value: 'Shared:info',
  },
  assignToRecruiter: {
    key: 'userInfo',
    icon: 'fas fa-user-plus',
    iconAlt: 'fas fa-user-plus',
    value: 'Shared:assign-to-users',
  },
  postJob: {
    key: 'postJob',
    icon: 'fas fa-external-link-alt',
    iconAlt: 'fas fa-external-link-alt',
    value: 'Shared:post-job',
  },
  permissions: {
    key: 'permissions',
    icon: 'fas fa-shield-alt',
    iconAlt: 'fas fa-shield-alt',
    value: 'Shared:permissions',
  },
  download: {
    key: 'download',
    icon: 'fas fa-download',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:download',
  },
  video: {
    key: 'video',
    icon: 'fas fa-video',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:video',
  },
  file: {
    key: 'file',
    icon: 'fas fa-file',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:file',
  },
  filter: {
    key: 'filter',
    icon: 'fas fa-filter',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:filter',
  },
  sync: {
    key: 'sync',
    icon: 'fas fa-sync',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:sync',
  },
  syncUsers: {
    key: 'sync_users',
    icon: 'fas fa-sync',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:sync-users',
  },
  refresh: {
    key: 'refresh',
    icon: 'fas fa-redo',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:refresh',
  },
  back: {
    key: 'back',
    icon: 'fas fa-arrow-left',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:back',
  },
  attachment: {
    key: 'attachment',
    icon: 'fas fa-paperclip',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:attachment',
  },
  reply: {
    key: 'reply',
    icon: 'fas fa-reply',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:reply',
  },
  replyAll: {
    key: 'replyAll',
    icon: 'fas fa-reply-all',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:replyAll',
  },
  filledStar: {
    key: 'filledStar',
    icon: 'fas fa-star',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:filledStar',
  },
  emptyStar: {
    key: 'emptyStar',
    icon: 'far fa-star',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:emptyStar',
  },
  openEnvelope: {
    key: 'openEnvelope',
    icon: 'far fa-envelope-open',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:openEnvelope',
  },
  closedEnvelope: {
    key: 'closedEnvelope',
    icon: 'far fa-envelope',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:closedEnvelope',
  },
  forward: {
    key: 'forward',
    icon: 'fas fa-share',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:forward',
  },
  leftAngle: {
    key: 'leftAngle',
    icon: 'fas fa-angle-left',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:leftAngle',
  },
  rightAngle: {
    key: 'rightAngle',
    icon: 'fas fa-angle-right',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:rightAngle',
  },
  upload: {
    key: 'upload',
    icon: 'fas fa-upload',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:upload',
  },
  remind: {
    key: 'upload',
    icon: 'fas fa-bell',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:upload',
  },
  deselect: {
    key: 'deselect',
    icon: 'fas fa-close',
    color: 'c-warning',
    bgColor: 'bg-warning',
    value: 'Shared:deselect',
  },
  moreInfo: {
    key: 'moreInfo',
    icon: 'fas fa-info',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:more-information',
  },
  allocate: {
    key: 'allocate',
    icon: 'fas fa-ticket-alt',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:allocate',
  },
  reserve: {
    key: 'reserve',
    icon: 'fas fa-ticket-alt',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:reserve',
  },
  reject: {
    key: 'reject',
    icon: 'fas fa-exclamation-triangle',
    color: 'c-danger',
    bgColor: 'bg-danger',
    value: 'Shared:reject',
  },
  viewDetails: {
    key: 'view-details',
    color: 'c-white',
    bgColor: 'bg-primary',
    value: 'Shared:view-details',
  },
  connect: {
    key: 'connect',
    color: 'c-white',
    bgColor: 'bg-primary',
    value: 'Shared:connect',
    icon: 'fas fa-link',
  },
  disconnect: {
    key: 'disconnect',
    color: 'c-white',
    bgColor: 'bg-primary',
    value: 'Shared:disconnect',
    icon: 'fas fa-unlink',
  },
  settings: {
    key: 'settings',
    color: 'c-white',
    bgColor: 'bg-primary',
    value: 'Shared:settings',
    icon: 'fas fa-cogs',
  },
  reminder: {
    key: 'remind',
    icon: 'fas fa-bell',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:reminder',
  },
  clone: {
    key: 'clone',
    icon: 'fas fa-copy',
    color: 'c-primary',
    bgColor: 'bg-primary',
    value: 'Shared:clone',
  },
};

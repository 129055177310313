export const NumberOfApprovalsEnum = [
  {
    key: 1,
    value: '1',
  },
  {
    key: 2,
    value: '2',
  },
  {
    key: 3,
    value: '3',
  },
  {
    key: 4,
    value: '4',
  },
  {
    key: 5,
    value: '5',
  },
];

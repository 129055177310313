// import { MainPermissionsTypesEnum } from '../../enums';

export const ManageFormBuilderOffersPermissions = {
  UpdateOfferStatus: {
    key: '9aaae3c4b4dd4a7d9656444e275a7617',
    // type: MainPermissionsTypesEnum.Update.key,
  },
  UploadManualOfferStatus: {
    key: '37cbe752eecb44249c92f84f3e47bec1',
    // type: MainPermissionsTypesEnum.Add.key,
  },
};

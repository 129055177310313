export const ReviewTypesEnum = {
  job: {
    key: 'job',
  },
  campaign: {
    key: 'campaign',
  },
  contract: {
    key: 'contract',
  },
  campaignDetails: {
    key: 'campaign_details',
  },
};

export const JobInviteRecruiterTypesEnum = {
  UsersAndEmployees: {
    key: 1,
    value: 'users/employees',
    type: 1,
  },
  Others: {
    key: 2,
    value: 'others',
    type: 1,
  },
};

export const PipelineStageBudgetedJobTypesEnum = {
  Budgeted: {
    key: true,
    value: 'budgeted',
  },
  NotBudgeted: {
    key: false,
    value: 'non-budgeted',
  },
};

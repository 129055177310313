export const ManageFlowPermissions = {
  CreateFlow: {
    key: 'c5cc999b7f094fbe904f32bc8347f2e5',
  },
  UpdateFlow: {
    key: '8de95c03896e49adb756755e4c982afa',
  },
  DeleteFlow: {
    key: '3c1cf08568e5460ba183d6ab3d517346',
  },
  ViewFlow: {
    key: '795c571c1b82432aa3ac762eb267ab77',
  },
};

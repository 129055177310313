export const FormsFollowOrderTypes = {
  Yes: {
    key: 1,
    value: 'yes',
  },
  No: {
    key: 2,
    value: 'no',
  },
};

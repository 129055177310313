export const FormsUTMSourcesTypesEnum = {
  Email: {
    key: 1,
    value: 'email',
  },
  Notification: {
    key: 2,
    value: 'notification',
  },
};

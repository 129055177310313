export const PipelineStagesEnum = {
  APPLIED: {
    key: 1,
    name: 'APPLIED',
  },
  DISQUALIFIED: {
    key: 4,
    name: 'DISQUALIFIED',
  },
  HIRED: {
    key: 6,
    name: 'HIRED',
  },
};

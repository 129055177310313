import { WorkflowsPermissions } from '../../permissions';

export const WorkflowsTemplatesTypesEnum = {
  JobRequisition: {
    key: 2,
    name: 'Job Requisition',
    value: 'job-requisition',
    description: 'job-requisition-description',
    icon: 'far fa-address-card',
    totalCount: 0,
    permissionid: WorkflowsPermissions.JobRequisition,
  },
  GeneralRequisition: {
    key: 1,
    name: 'General Requisition',
    value: 'general-requisition',
    description: 'general-requisition-description',
    icon: 'far fa-file-alt',
    totalCount: 0,
    permissionid: WorkflowsPermissions.GeneralRequisition,
  },
  JobOffer: {
    key: 3,
    name: 'Job Offer',
    value: 'job-offer',
    description: 'job-offer-description',
    icon: 'fas fa-briefcase',
    totalCount: 0,
    permissionid: WorkflowsPermissions.OfferApproval,
  },
  Contract: {
    key: 4,
    name: 'Contract',
    value: 'contract',
    description: 'contract-description',
    icon: 'fas fa-file-contract',
    totalCount: 0,
    permissionid: WorkflowsPermissions.ContractApproval,
  },
  Interview: {
    key: 5,
    name: 'Interview',
    value: 'interview',
    description: 'interview-description',
    icon: 'fas fa-user-tie',
    totalCount: 0,
    permissionid: WorkflowsPermissions.InterviewApproval,
  },
  Rehire: {
    key: 6,
    name: 'Rehire',
    value: 'rehire',
    description: 'rehire-description',
    icon: 'fas fa-user-check',
    totalCount: 0,
    permissionid: WorkflowsPermissions.RehireApproval,
  },
  Relative: {
    key: 7,
    name: 'Relative',
    value: 'relative',
    description: 'relative-description',
    icon: 'fas fa-users',
    totalCount: 0,
    permissionid: WorkflowsPermissions.RelativeApproval,
  },
};

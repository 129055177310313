export const EquationOperatorsEnum = [
  '+',
  '-',
  '*',
  '/',
  '%',
  '(',
  ')',
  {
    type: 'number',
    value: 0,
  },
];

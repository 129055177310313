export const MainPermissionsTypesEnum = {
  Add: {
    key: 1,
  },
  Delete: {
    key: 2,
  },
  Update: {
    key: 3,
  },
  View: {
    key: 4,
  },
};

export const SpaceAndFolderMembersTypesEnum = {
  Hierarchy: {
    key: 1,
    value: 'hierarchy',
  },
  Users: {
    key: 2,
    value: 'users',
  },
};

export const IndeedQuestionsTypesEnum = {
  text: {
    key: 'text',
    value: 'text',
  },
  file: {
    key: 'file',
    value: 'file',
  },
  select: {
    key: 'select',
    value: 'select',
  },
  multiselect: {
    key: 'multiselect',
    value: 'multiselect',
  },
};

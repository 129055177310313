export const CandidateQuestionnaireStatusesEnum = {
  Completed: {
    key: 'completed',
    value: 'completed',
  },
  Uncompleted: {
    key: 'uncompleted',
    value: 'uncompleted',
  },
};

export const EvaBrandUploaderMediaTypesEnum = {
  Icon: {
    key: 0,
    value: 'icon',
  },
  Image: {
    key: 1,
    value: 'image',
  },
  video: {
    key: 2,
    value: 'video',
  },
  Youtube: {
    key: 3,
    value: 'youtube',
  },
};

export const WorkflowsOperationsEnum = {
  Or: {
    key: 1,
    value: 'or',
    color: 'warning',
  },
  And: {
    key: 2,
    value: 'and',
    color: 'green-primary',
  },
};

export const AzureSyncScheduleTypeEnum = {
  daily: {
    key: 'daily',
    value: 'daily',
  },
  weekly: {
    key: 'weekly',
    value: 'weekly',
  },
  monthly: {
    key: 'monthly',
    value: 'monthly',
  },
};

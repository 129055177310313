export const CandidateDocumentsPublishStatusesEnum = {
  Yes: {
    key: 'Y',
    value: 'yes',
  },
  No: {
    key: 'N',
    value: 'no',
  },
};

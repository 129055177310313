export const EvaBrandJobLimitsEnum = {
  2: {
    key: 2,
    value: 2,
  },
  4: {
    key: 4,
    value: 4,
  },
  6: {
    key: 6,
    value: 6,
  },
  8: {
    key: 8,
    value: 8,
  },
  10: {
    key: 10,
    value: 10,
  },
  12: {
    key: 12,
    value: 12,
  },
  14: {
    key: 14,
    value: 14,
  },
};
